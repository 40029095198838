import React from 'react';
import { connect } from 'react-redux';

import ResultsTable from 'common/components/ResultsTable';
import I18n from 'common/i18n';

import { COLUMN_NAMES } from 'organizationDashboard/components/Users/OrganizationUsersTable/constants';
import ErrorMessage from 'organizationDashboard/components/Users/OrganizationUsersTable/ErrorMessage';
import { changeSiteAdminsSortColumn } from 'organizationDashboard/components/Sites/actions';
import {
  getFetchedSiteAdmins,
  getSiteAdminsSearchParams
} from 'organizationDashboard/components/Sites/selectors';

import { ApplicationState, SearchParameters } from 'organizationDashboard/types';

interface Props {
  doChangeSortColumn: typeof changeSiteAdminsSortColumn;
  currentSearchParams: SearchParameters;
  siteAdmins: ReturnType<typeof getFetchedSiteAdmins>;
}

/** Table that handles showing, sorting, searching, creating, and deleting org users */
const SiteAdminsTable = ({ currentSearchParams, siteAdmins, doChangeSortColumn }: Props) => {
  const { sortBy, sortOrder } = currentSearchParams;
  return (
    <div>
      <ErrorMessage />

      <ResultsTable
        loadingData={siteAdmins === null}
        data={siteAdmins || []}
        rowKey="email"
        noResultsMessage={I18n.t('org_dashboard.sites.modals.site_administrators.table.no_results')}
        id="org-users-table"
      >
        {/* Display Name */}
        <ResultsTable.Column
          header={I18n.t('org_dashboard.sites.modals.site_administrators.table.display_name.header')}
          dataIndex="displayName"
          isActive={sortBy === COLUMN_NAMES.DISPLAY_NAME}
          onSort={() => doChangeSortColumn(COLUMN_NAMES.DISPLAY_NAME)}
          sortDirection={sortOrder}
        />

        {/* Email */}
        <ResultsTable.Column
          header={I18n.t('org_dashboard.sites.modals.site_administrators.table.email.header')}
          dataIndex="email"
          isActive={sortBy === COLUMN_NAMES.EMAIL}
          onSort={() => doChangeSortColumn(COLUMN_NAMES.EMAIL)}
          sortDirection={sortOrder}
        />
      </ResultsTable>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  siteAdmins: getFetchedSiteAdmins(state),
  currentSearchParams: getSiteAdminsSearchParams(state)
});

const mapDispatchToProps = {
  doChangeSortColumn: changeSiteAdminsSortColumn
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteAdminsTable);
