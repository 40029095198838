import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { routerReducer } from 'react-router-redux';

import 'common/types/windowSocrata';

import Dashboard from './components/Dashboard';
import UsersSagas from './components/Users/sagas';
import OrgSagas from './sagas/OrganizationSagas';
import SiteSagas from './components/Sites/sagas';
import UsersReducer from './components/Users/reducer';
import OrgReducer from './reducers/OrganizationReducer';
import SitesReducer from './reducers/SitesReducer';

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'OrganizationDashboard' })) ||
  compose;

const rootReducer = combineReducers({
  organization: OrgReducer,
  users: UsersReducer,
  sites: SitesReducer,
  routing: routerReducer
});

export default function* rootSaga() {
  yield all([...OrgSagas, ...UsersSagas, ...SiteSagas]);
}

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

render(<Dashboard store={store as any} />, document.getElementById('main'));
