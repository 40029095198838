import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';
import I18n from 'common/i18n';
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';

import AdminsTable from '../AdminsTable';
import { getAdminsModalState } from '../selectors';
import { closeAdminsModal } from './actions';

import { ApplicationState } from 'organizationDashboard/types';

import './site-admins-modal.scss';

interface Props {
  doCloseModal: typeof closeAdminsModal;
  showModal: boolean;
}

const AdminsModal = ({ showModal, doCloseModal }: Props) => {
  if (showModal) {
    return (
      <Modal className="admin-modal" fullScreen={false} onDismiss={doCloseModal}>
        <ModalHeader showCloseButton={true} onDismiss={doCloseModal}>
          <h5>{I18n.t('org_dashboard.sites.modals.site_administrators.title')}</h5>
        </ModalHeader>

        <ModalContent className="admin-modal__content">
          <div className="admins-modal__table">
            <AdminsTable />
          </div>
        </ModalContent>

        <ModalFooter>
          <div className="admin-modal__footer-buttons">
            <Button onClick={doCloseModal}>
              {I18n.t('org_dashboard.sites.modals.site_administrators.close')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
  return null;
};

const mapStateToProps = (state: ApplicationState) => ({
  showModal: getAdminsModalState(state)
});

const mapDispatchToProps = {
  doCloseModal: closeAdminsModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminsModal);
