import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import I18n from 'common/i18n';
import ResultsTable from 'common/components/ResultsTable';
import PillBadge from 'common/components/PillBadge';
import LocalizedRoleName from 'common/i18n/components/LocalizedRoleName';

import { COLUMN_NAMES, ORGANIZATION_USER_STATUS } from './constants';
import ActionsDropdownButton from './ActionsDropdownButton';
import Pagination from './Pagination';
import ErrorMessage from './ErrorMessage';
import { changeSortColumn } from './actions';
import { getCurrentPage, getCurrentSearchParams, getMemoizedSiteNames } from './selectors';
import OrganizationSites from './OrganizationSites';
import { getRolesMap, getOrgMemberRole } from 'organizationDashboard/components/selectors';

import { UserDomain, SearchParameters, Status, User, ApplicationState } from 'organizationDashboard/types';

import './organization-users.scss';

interface Props {
  currentPage: User[];
  currentSearchParams: SearchParameters;
  memoizedSiteNames: any;
  doChangeSortColumn: typeof changeSortColumn;
  roles: ReturnType<typeof getRolesMap>;
  orgMemberRole: ReturnType<typeof getOrgMemberRole>;
}

/** Table that handles showing, sorting, searching, creating, and deleting org users */
class OrganizationUsersTable extends Component<Props> {
  render() {
    const { orgMemberRole, roles, currentPage, currentSearchParams, doChangeSortColumn, memoizedSiteNames } =
      this.props;
    const { sortBy, sortOrder } = currentSearchParams;

    return (
      <div>
        <ErrorMessage />

        <ResultsTable
          loadingData={currentPage === null}
          data={currentPage || []}
          rowKey="email"
          noResultsMessage={I18n.t('org_dashboard.users.table.no_results')}
          id="org-users-table"
        >
          {/* Email */}
          <ResultsTable.Column
            header={I18n.t('org_dashboard.users.table.email.header')}
            dataIndex="email"
            isActive={sortBy === COLUMN_NAMES.EMAIL}
            onSort={() => doChangeSortColumn(COLUMN_NAMES.EMAIL)}
            sortDirection={sortOrder}
          />

          {/* Account Status */}
          <ResultsTable.Column header={I18n.t('org_dashboard.users.table.status.header')} dataIndex="status">
            {(status: Status) => (
              <span>
                {status === ORGANIZATION_USER_STATUS.DEACTIVATED && (
                  <PillBadge>{I18n.t('org_dashboard.users.deactivated')}</PillBadge>
                )}
                {status === ORGANIZATION_USER_STATUS.PENDING && (
                  <PillBadge type="primary">{I18n.t('org_dashboard.users.pending')}</PillBadge>
                )}
              </span>
            )}
          </ResultsTable.Column>

          {/* Organization Role */}
          <ResultsTable.Column
            header={I18n.t('org_dashboard.users.table.org_role.header')}
            dataIndex="organizationRoleId"
            isActive={sortBy === COLUMN_NAMES.ORG_ROLE}
          >
            {(roleId: number | undefined) => {
              // Might want to assign a role object to every user in a reducer someday,
              // but we've segregated users state and organization state (which holds role info).
              let role;
              if (roleId !== undefined) {
                role = roles[roleId] || orgMemberRole;
              } else {
                role = orgMemberRole;
              }

              return <LocalizedRoleName role={role} />;
            }}
          </ResultsTable.Column>
          {/* Users Sites */}
          <ResultsTable.Column header={I18n.t('org_dashboard.users.table.sites.header')} dataIndex="domains">
            {(domains: UserDomain[]) => (
              <OrganizationSites sites={domains} cNameToNameTranslations={memoizedSiteNames} />
            )}
          </ResultsTable.Column>
          {/* Display Name */}
          <ResultsTable.Column
            header={I18n.t('org_dashboard.users.table.display_name.header')}
            dataIndex="displayName"
            isActive={sortBy === COLUMN_NAMES.DISPLAY_NAME}
            onSort={() => doChangeSortColumn(COLUMN_NAMES.DISPLAY_NAME)}
            sortDirection={sortOrder}
          />

          {/* Last Authenticated At */}
          <ResultsTable.Column
            header={I18n.t('org_dashboard.users.table.last_authenticated_to_org.header')}
            dataIndex="lastAuthenticatedToOrganizationAt"
            isActive={sortBy === COLUMN_NAMES.LAST_AUTHENTICATED_TO_ORG}
            onSort={() => doChangeSortColumn(COLUMN_NAMES.LAST_AUTHENTICATED_TO_ORG)}
            sortDirection={sortOrder}
          >
            {(lastAuthenticatedToOrganizationAt: string) => (
              <span>
                {lastAuthenticatedToOrganizationAt &&
                  moment(lastAuthenticatedToOrganizationAt).format('LLLL')}
              </span>
            )}
          </ResultsTable.Column>

          {/* Actions */}
          <ResultsTable.Column header={I18n.t('org_dashboard.users.table.actions.header')} dataIndex="email">
            {(email: string, user: User) => <ActionsDropdownButton email={email} user={user} />}
          </ResultsTable.Column>
        </ResultsTable>

        <Pagination />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentPage: getCurrentPage(state),
  currentSearchParams: getCurrentSearchParams(state),
  memoizedSiteNames: getMemoizedSiteNames(state),
  roles: getRolesMap(state),
  orgMemberRole: getOrgMemberRole(state)
});

const mapDispatchToProps = {
  doChangeSortColumn: changeSortColumn
};
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUsersTable);
