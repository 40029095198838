import merge from 'lodash/merge';
import get from 'lodash/get';

import { SORT_DIRECTION } from 'common/Constants';

import { defaultUserState } from './constants';
import * as usersTableActions from './OrganizationUsersTable/actions';
import * as actionsBarActions from './ActionsBar/actions';
import * as addUsersModalActions from './AddUsersModal/actions';
import * as actions from './actions';

import { SearchParameters, Status, User, Users } from 'organizationDashboard/types';

const resetSeek = (state: Users): Users => {
  return {
    ...state,
    currentSearchParams: { ...state.currentSearchParams, seekEmail: '' },
    currentPage: [],
    previousSeeks: [],
    hasNextPage: false
  };
};

const fetchCurrentPageSuccess = (
  state: Users,
  { currentPage, hasNextPage }: { currentPage: User[]; hasNextPage: boolean }
) => ({
  ...state,
  currentPage,
  hasNextPage
});

const fetchCurrentPageFail = (state: Users, { error }: any): Users => {
  console.error('Error fetching users', error, get(error, 'json', 'no message body'));

  // clear out the state
  return merge({}, resetSeek(state), { searchInputValue: '', error });
};

const goToNextPage = (state: Users): Users => {
  const { currentPage, currentSearchParams, previousSeeks } = state;

  /** seek by email */
  const seekEmail = currentPage[currentPage.length - 1].email;

  return {
    ...state,
    previousSeeks: [get(currentSearchParams, 'seekEmail', ''), ...previousSeeks],
    currentSearchParams: {
      ...currentSearchParams,
      seekEmail
    }
  };
};

const goToPreviousPage = (state: Users): Users => {
  const { currentSearchParams, previousSeeks } = state;

  // basically, pop the last seek off the stack
  const [seekEmail, ...leftoverPreviousSeeks] = previousSeeks;

  return {
    ...state,
    previousSeeks: leftoverPreviousSeeks,
    currentSearchParams: {
      ...currentSearchParams,
      seekEmail
    }
  };
};

const changeSortColumn = (state: Users, { columnName }: { columnName: string }): Users => {
  const { currentSearchParams } = state;

  const { sortBy, sortOrder } = currentSearchParams;

  if (columnName === sortBy) {
    // if we're re-selecting the column we're sorting by, reverse direction
    return {
      ...state,
      currentSearchParams: {
        ...currentSearchParams,
        sortOrder: sortOrder === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
        seekEmail: ''
      },
      previousSeeks: []
    };
  } else {
    // otherwise, we're selecting a new column
    return {
      ...state,
      currentSearchParams: {
        ...currentSearchParams,
        sortBy: columnName,
        sortOrder: SORT_DIRECTION.DESC,
        seekEmail: ''
      },
      previousSeeks: []
    };
  }
};

const searchInputChange = (state: Users, { value }: { value: string }): Users => ({
  ...state,
  searchInputValue: value
});

const searchParamsChange = (state: Users, { value }: { value: SearchParameters }): Users =>
  merge(state, { currentSearchParams: { ...value } });

const applySearch = (state: Users) => {
  const { currentSearchParams, searchInputValue } = state;
  const { sortBy, sortOrder } = currentSearchParams;

  // changing the state automatically triggers a page fetch if it's different
  return {
    ...state,
    currentSearchParams: {
      ...currentSearchParams,
      q: searchInputValue,

      sortBy,
      sortOrder,

      // back to page 0
      seekEmail: ''
    },
    previousSeeks: []
  };
};

const applySetUserRole = (state: Users, { userId, roleId }: { userId: string; roleId: number }): Users => {
  const { currentPage } = state;

  const updatedCurrentPage = currentPage.map((user) =>
    user.uid === userId ? { ...user, organizationRoleId: roleId } : user
  );

  return {
    ...state,
    currentPage: updatedCurrentPage
  };
};

const applySetUserStatus = (state: Users, { user, status }: { user: User; status: Status }): Users => {
  const { currentPage } = state;

  const updatedCurrentPage = currentPage.map((userOnPage) =>
    userOnPage.uid === user.uid ? { ...userOnPage, status: status } : userOnPage
  );

  return {
    ...state,
    currentPage: updatedCurrentPage
  };
};

export default (state = defaultUserState, action: { type: string; payload?: any }): Users => {
  switch (action.type) {
    // addUsersModalActions
    case addUsersModalActions.ADD_USERS_FAILED:
      console.error(get(action.payload, 'error', {}));
      return state;
    case addUsersModalActions.ADD_USERS_SUCCESS:
      return state;

    // actions bar actions
    case actionsBarActions.OPEN_ADD_USERS_MODAL:
      return { ...state, addUsersModalOpen: true };
    case actionsBarActions.CLOSE_ADD_USER_MODAL:
      return { ...state, addUsersModalOpen: false };
    case actions.RESET_SEEK:
      return resetSeek(state);
    // searching
    case actionsBarActions.ORG_USERS_SEARCH_INPUT_CHANGE:
      return searchInputChange(state, action.payload);
    case actionsBarActions.ORG_USERS_APPLY_SEARCH:
      return applySearch(state);
    case actionsBarActions.ORG_USERS_SEARCH_PARAMS_CHANGE:
      return searchParamsChange(state, action.payload);
    // users table actions
    // fetch current page
    case usersTableActions.ORG_USERS_FETCH_CURRENT_PAGE_SUCCESS:
      return fetchCurrentPageSuccess(state, action.payload);
    case usersTableActions.ORG_USERS_FETCH_CURRENT_PAGE_FAIL:
      return fetchCurrentPageFail(state, action.payload);
    case actions.SET_USER_COUNT:
      return { ...state, totalNumberOfUsers: action.payload };
    // prev/next page
    case usersTableActions.ORG_USERS_GO_TO_NEXT_PAGE:
      return goToNextPage(state);
    case usersTableActions.ORG_USERS_GO_TO_PREVIOUS_PAGE:
      return goToPreviousPage(state);

    // change sort order
    case usersTableActions.ORG_USERS_CHANGE_SORT_COLUMN:
      return changeSortColumn(state, action.payload);

    // individual user actions
    case usersTableActions.SET_USER_ROLE_SUCCESS:
      return applySetUserRole(state, action.payload);
    case usersTableActions.SET_USER_STATUS_SUCCESS:
      return applySetUserStatus(state, action.payload);

    default:
      return state;
  }
};
