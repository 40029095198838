import get from 'lodash/get';

import { getUsers, getOrg } from 'organizationDashboard/components/selectors';
import { ApplicationState } from 'organizationDashboard/types';

export const getCurrentPage = (state: ApplicationState) => get(getUsers(state), 'currentPage');
export const getCurrentSearchParams = (state: ApplicationState) =>
  get(getUsers(state), 'currentSearchParams');

export const getPreviousSeeks = (state: ApplicationState) => get(getUsers(state), 'previousSeeks');
export const getHasNextPage = (state: ApplicationState) => get(getUsers(state), 'hasNextPage');

export const getError = (state: ApplicationState) => get(getUsers(state), 'error');

export const getMemoizedSiteNames = (state: ApplicationState) => get(getOrg(state), 'sites', {});
