import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { goToNextPage, goToPreviousPage } from './actions';
import { getPreviousSeeks, getHasNextPage } from './selectors';
import { getNumberOfUsers } from 'organizationDashboard/components/selectors';
import { PAGE_SIZE } from './constants';
import DownloadCSV from 'organizationDashboard/components/Users/OrganizationUsersTable/DownloadAll';

import { ApplicationState } from 'organizationDashboard/types';

export interface Props {
  previousSeeks: ReturnType<typeof getPreviousSeeks>;
  hasNextPage: ReturnType<typeof getHasNextPage>;
  doGoToNextPage: typeof goToNextPage;
  doGoToPreviousPage: typeof goToPreviousPage;
  numberOfUsers: number;
}

export const Pagination = ({
  numberOfUsers,
  doGoToNextPage,
  doGoToPreviousPage,
  previousSeeks,
  hasNextPage
}: Props) => {
  const previousLinkClasses = classNames('prev-link', { disabled: previousSeeks.length === 0 });
  const nextLinkClasses = classNames('next-link', { disabled: !hasNextPage });
  const startItemNumOnPage = previousSeeks.length * PAGE_SIZE + 1;
  let endItemNumOnPage;
  if (startItemNumOnPage > numberOfUsers - PAGE_SIZE - 1) {
    endItemNumOnPage = numberOfUsers;
  } else {
    endItemNumOnPage = (previousSeeks.length + 1) * PAGE_SIZE;
  }

  return (
    <div>
      <a className={previousLinkClasses} onClick={previousSeeks.length !== 0 ? doGoToPreviousPage : () => {}}>
        <SocrataIcon name={IconName.ArrowLeft} />
      </a>
      <a className={nextLinkClasses} onClick={hasNextPage ? doGoToNextPage : () => {}}>
        <SocrataIcon name={IconName.ArrowRight} />
      </a>
      <span>
        {I18n.t('org_dashboard.users.table.pagination.show_results', {
          startItemNumOnPage,
          endItemNumOnPage,
          numberOfUsers
        })}
      </span>
      <DownloadCSV />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  previousSeeks: getPreviousSeeks(state),
  hasNextPage: getHasNextPage(state),
  numberOfUsers: getNumberOfUsers(state)
});

const mapDispatchToProps = {
  doGoToNextPage: goToNextPage,
  doGoToPreviousPage: goToPreviousPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
