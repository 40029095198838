import { action } from 'typesafe-actions';
import { AddUsersSuccessResponse } from '../types';

export const ADD_USERS = 'ADD_USERS';
export const addUsers = (emails: string, roleId: number) => action(ADD_USERS, { emails, roleId });

export const ADD_USERS_FAILED = 'ADD_USERS_FAILED';
export const addUsersFailed = (error: any) => action(ADD_USERS_FAILED, { error });

export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const addUsersSuccess = (payload: AddUsersSuccessResponse) => action(ADD_USERS_SUCCESS, payload);
