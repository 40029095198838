import { Status } from 'organizationDashboard/types';

export const PAGE_SIZE = 10;

export const COLUMN_NAMES = {
  EMAIL: 'email',
  ORG_ROLE: 'orgRole',
  DISPLAY_NAME: 'displayName',
  LAST_AUTHENTICATED_TO_ORG: 'lastAuthenticatedToOrganizationAt'
};

export const ERROR_CODES = {
  AUTHENTICATION_REQUIRED: 'authentication_required',
  INVALID_SORT_COLUMN: 'ORGANIZATION_USER.INVALID_SORT_COLUMN',
  INVALID_PAGE_SIZE: 'ORGANIZATION_USER.INVALID_PAGE_SIZE'
};

export const ORGANIZATION_USER_STATUS: {
  ALL: Status;
  ACTIVE: Status;
  PENDING: Status;
  DEACTIVATED: Status;
} = {
  ALL: 'all',
  ACTIVE: 'active',
  PENDING: 'pending',
  DEACTIVATED: 'deactivated'
};
