import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import I18n from 'common/i18n';

import { getError } from './selectors';
import { ERROR_CODES } from './constants';

import { ApplicationState } from 'organizationDashboard/types';

const getErrorMessage = (error: { json: { code: string } }) => {
  switch (get(error, 'json.code')) {
    case ERROR_CODES.AUTHENTICATION_REQUIRED:
      return I18n.t('org_dashboard.users.errors.authentication_required_html');
    case ERROR_CODES.INVALID_SORT_COLUMN:
      return I18n.t('org_dashboard.users.errors.invalid_sort_column');
    case ERROR_CODES.INVALID_PAGE_SIZE:
      return I18n.t('org_dashboard.users.errors.invalid_page_size');
    default:
      return I18n.t('org_dashboard.users.errors.unknown_fetch', {
        requestId: get(error, 'requestId', '(unknown)')
      });
  }
};

interface Props {
  error: ReturnType<typeof getError>;
}
const ErrorMessage = (props: Props) => {
  const { error } = props;

  if (!error) {
    return null;
  }

  return <div className="alert error" dangerouslySetInnerHTML={{ __html: getErrorMessage(error) }} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  error: getError(state)
});

export default connect(mapStateToProps)(ErrorMessage);
