import React, { Component } from 'react';

import I18n from 'common/i18n';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';

import { Domain } from 'organizationDashboard/types';

interface Props {
  site: Domain;
}

class SuperadminDropdownButton extends Component<Props> {
  handleGoToInternal = () => {
    const { site } = this.props;

    window.open(`/internal/domains/${site.cname}`, '_blank');
  };

  render() {
    return (
      <DropdownButton>
        {/*
          This has to be an array or DropdownButton prop checking complains
          Once there are more DropdownItems here, the wrapping array can be removed
        */}
        {[
          <DropdownItem key="manage-internal" onClick={this.handleGoToInternal}>
            {I18n.t('org_dashboard.sites.table.superadmin_actions.manage_on_internal')}
          </DropdownItem>
        ]}
      </DropdownButton>
    );
  }
}

export default SuperadminDropdownButton;
