import * as actions from '../actions/OrganizationActions';
import { organizationActionType } from '../actions/OrganizationActions';

import { Organization } from 'organizationDashboard/types';

export const defaultType = {
  id: -1,
  name: '',
  dashboardDomainId: -1,
  numberOfDomains: -1,
  domains: [],
  userRoles: [],
  userRolesDictionary: {},
  userStatus: [],
  sites: {},
  error: null
};

const fetchOrgSuccess = (
  state: Organization,
  { organization }: { organization: Organization }
): Organization => {
  return {
    ...state,
    ...organization,
    error: null
  };
};

const fetchOrgFailure = (state: Organization, { error }: { error: any }): Organization => {
  return {
    ...state,
    error
  };
};

export default (state = defaultType, action: organizationActionType) => {
  switch (action.type) {
    case actions.FETCH_ORG_SUCCESS:
      return fetchOrgSuccess(state, action.payload);
    case actions.FETCH_ORG_FAILED:
      return fetchOrgFailure(state, action.payload);
    default:
      return state;
  }
};
