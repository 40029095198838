import { action } from 'typesafe-actions';

import { Status, User } from 'organizationDashboard/types';
// tells the saga to fetch the current page with whatever currentSearchParams are
export const ORG_USERS_FETCH_CURRENT_PAGE = 'ORG_USERS_FETCH_CURRENT_PAGE';
export const fetchCurrentPage = () => action(ORG_USERS_FETCH_CURRENT_PAGE);

// sagas reporting a successful page fetch
export const ORG_USERS_FETCH_CURRENT_PAGE_SUCCESS = 'ORG_USERS_FETCH_CURRENT_PAGE_SUCCESS';
export const fetchCurrentPageSuccess = (currentPage: User[], hasNextPage: boolean) =>
  action(ORG_USERS_FETCH_CURRENT_PAGE_SUCCESS, { currentPage, hasNextPage });

// sagas reporting a failed page fetch
export const ORG_USERS_FETCH_CURRENT_PAGE_FAIL = 'ORG_USERS_FETCH_CURRENT_PAGE_FAIL';
export const fetchCurrentPageFail = (error: any) => action(ORG_USERS_FETCH_CURRENT_PAGE_FAIL, { error });

// go to the next page; note that this only updated currentSearchParams and doesn't actually fetch the next page
// (dispatch ORG_USERS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const ORG_USERS_GO_TO_NEXT_PAGE = 'ORG_USERS_GO_TO_NEXT_PAGE';
export const goToNextPage = () => action(ORG_USERS_GO_TO_NEXT_PAGE);

// go to the next page; note that this only updated currentSearchParams and doesn't actually fetch the next page
// (dispatch ORG_USERS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const ORG_USERS_GO_TO_PREVIOUS_PAGE = 'ORG_USERS_GO_TO_PREVIOUS_PAGE';
export const goToPreviousPage = () => action(ORG_USERS_GO_TO_PREVIOUS_PAGE);

// changes the column that we're sorting by in currentSearchParams
// (dispatch ORG_USERS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const ORG_USERS_CHANGE_SORT_COLUMN = 'ORG_USERS_CHANGE_SORT_COLUMN';
export const changeSortColumn = (columnName: string) => action(ORG_USERS_CHANGE_SORT_COLUMN, { columnName });

export const SET_USER_ROLE = 'SET_USER_ROLE';
export const setUserRole = (userId: string, roleId: number) => action(SET_USER_ROLE, { userId, roleId });

export const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS';
export const setUserRoleSuccess = (userId: string, roleId: number, payload: any) =>
  action(SET_USER_ROLE_SUCCESS, { userId, roleId, payload });

export const SET_USER_STATUS_ACTIVE = 'SET_USER_STATUS_ACTIVE';
export const setUserStatusActive = (user: User) => action(SET_USER_STATUS_ACTIVE, { user });

export const SET_USER_STATUS_DEACTIVATED = 'SET_USER_STATUS_DEACTIVATED';
export const setUserStatusDeactivated = (user: User) => action(SET_USER_STATUS_DEACTIVATED, { user });

export const SET_USER_STATUS_SUCCESS = 'SET_USER_STATUS_SUCCESS';
export const setUserStatusSuccess = (user: User, status: Status, payload: any) =>
  action(SET_USER_STATUS_SUCCESS, { user, status, payload });
