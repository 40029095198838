import React, { Component } from 'react';
import { connect } from 'react-redux';

import DropdownWithLabel, { DropdownSize } from 'common/components/Forms/Dropdown';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';
import I18n from 'common/i18n';

import SearchBar from './SearchBar';
import AddAdministratorsButton from './AddAdministratorsButton';
import AddUsersModal from '../AddUsersModal';
import { getFilterableStatus, getFilterableUserRoles } from './selectors';
import { getFilterableSites } from 'organizationDashboard/components/selectors';
import { searchParamChange } from './actions';
import { createDropdownOptionsWithDefault } from '../OrganizationUsersTable/util';

import { ApplicationState } from 'organizationDashboard/types';

import './actions-bar.scss';

interface Props {
  filterableStatus: ReturnType<typeof getFilterableStatus>;
  filterableSites: ReturnType<typeof getFilterableSites>;
  filterableRoles: ReturnType<typeof getFilterableUserRoles>;
  filterOnParam: typeof searchParamChange;
}

interface State {
  statusSelected: number | string;
  siteSelected: number | string;
  roleSelected: number | string;
}

export class ActionsBar extends Component<Props, State> {
  state = {
    statusSelected: -1,
    siteSelected: -1,
    roleSelected: -1
  };

  handleSiteSelect = ({ value }: { value: string | number }) => {
    const { filterOnParam } = this.props;
    filterOnParam({ domainCname: value });
    this.setState({ siteSelected: value });
  };

  handleStatusSelect = ({ value }: { value: string | number }) => {
    const { filterOnParam } = this.props;
    filterOnParam({ status: value });
    this.setState({ statusSelected: value });
  };

  handleRoleSelect = ({ value }: { value: string | number }) => {
    const { filterOnParam } = this.props;
    filterOnParam({ roleId: value });
    this.setState({ roleSelected: value });
  };

  render() {
    const { filterableStatus, filterableSites, filterableRoles } = this.props;
    const { statusSelected, siteSelected, roleSelected } = this.state;
    return (
      <div className="actions-bar">
        <div className="left">
          <SearchBar />
          <DropdownWithLabel<string | number>
            dropdownLabel="status"
            labelContent="Status"
            wrapperClassName="dropdown-container wide-dropdown-container"
            picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
            value={statusSelected}
            handleSelection={this.handleStatusSelect}
            size={DropdownSize.MEDIUM}
            options={filterableStatus}
          />
          <DropdownWithLabel<string | number>
            dropdownLabel="sites"
            labelContent="Sites"
            wrapperClassName="dropdown-container wide-dropdown-container"
            picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
            value={siteSelected}
            handleSelection={this.handleSiteSelect}
            size={DropdownSize.MEDIUM}
            options={createDropdownOptionsWithDefault(
              I18n.t('org_dashboard.org_selectors.sites.default'),
              filterableSites
            )}
          />
          <DropdownWithLabel<string | number>
            dropdownLabel="roles"
            labelContent="Roles"
            wrapperClassName="dropdown-container wide-dropdown-container"
            picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
            value={roleSelected}
            handleSelection={this.handleRoleSelect}
            size={DropdownSize.MEDIUM}
            options={createDropdownOptionsWithDefault(
              I18n.t('org_dashboard.org_selectors.roles.default'),
              filterableRoles
            )}
          />
        </div>
        <div className="right">
          <AddAdministratorsButton />
          <AddUsersModal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  filterableStatus: getFilterableStatus(state),
  filterableSites: getFilterableSites(state),
  filterableRoles: getFilterableUserRoles(state)
});

const mapDispatchToProps = {
  filterOnParam: searchParamChange
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsBar);
