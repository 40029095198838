import React, { Component } from 'react';
import { connect } from 'react-redux';
import merge from 'lodash/merge';

import Button from 'common/components/Button';

import { defaultSearchParams, MAX_ADMINS_TO_FETCH } from 'organizationDashboard/constants';
import { administratorId } from 'organizationDashboard/components/Sites/constants';
import { status } from 'organizationDashboard/components/Users/constants';
import { openAdminsModal } from './actions';
import { setAdminSearchParams, fetchAdminUsers } from 'organizationDashboard/components/Sites/actions';

import { Domain } from 'organizationDashboard/types';

interface Props {
  openModal: typeof openAdminsModal;
  numberOfSiteAdmins: number;
  site: Domain;
  setSearchParams: typeof setAdminSearchParams;
  fetchSiteAdmins: typeof fetchAdminUsers;
}

class AdminModalButton extends Component<Props> {
  onClick = () => {
    const { openModal, site, setSearchParams, fetchSiteAdmins } = this.props;
    const { cname } = site;

    const filterParameters = merge({}, defaultSearchParams, {
      limit: MAX_ADMINS_TO_FETCH,
      roleId: administratorId,
      domainCname: cname,
      status: status.ACTIVE
    });

    setSearchParams(filterParameters);
    fetchSiteAdmins();

    openModal();
  };

  render() {
    const { numberOfSiteAdmins } = this.props;
    return <Button onClick={this.onClick}>{numberOfSiteAdmins}</Button>;
  }
}

const mapDispatchToProps = {
  setSearchParams: setAdminSearchParams,
  fetchSiteAdmins: fetchAdminUsers,
  openModal: openAdminsModal
};

export default connect(null, mapDispatchToProps)(AdminModalButton);
