import { SORT_DIRECTION } from 'common/Constants';

import * as modalActions from 'organizationDashboard/components/Sites/AdminsModal/actions';
import * as sitesActions from 'organizationDashboard/components/Sites/actions';
import { defaultSearchParams } from 'organizationDashboard/constants';
import { sitesActionType } from 'organizationDashboard/components/Sites/actions';
import { adminsModalType } from 'organizationDashboard/components/Sites/AdminsModal/actions';

import { Sites } from 'organizationDashboard/types';

export const defaultState: Sites = {
  adminSearchParams: defaultSearchParams,
  adminUsers: [],
  error: null,
  adminsModal: {
    open: false
  }
};

const changeSortColumn = (state: Sites, { columnName }: { columnName: string }) => {
  const { adminSearchParams } = state;
  const { sortBy, sortOrder } = adminSearchParams;
  if (columnName === sortBy) {
    // if we're re-selecting the column we're sorting by, reverse direction
    return {
      ...state,
      adminSearchParams: {
        ...adminSearchParams,
        sortOrder: sortOrder === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
        seekEmail: null
      }
    };
  } else {
    // otherwise, we're selecting a new column
    return {
      ...state,
      adminSearchParams: {
        ...adminSearchParams,
        sortBy: columnName,
        sortOrder: SORT_DIRECTION.DESC,
        seekEmail: null
      }
    };
  }
};

export default (state = defaultState, action: sitesActionType | adminsModalType) => {
  switch (action.type) {
    case sitesActions.SET_ADMIN_SEARCH_PARAMS:
      return { ...state, ...{ ...action.payload } };
    case sitesActions.SITE_ADMINS_CHANGE_SORT_COLUMN:
      return changeSortColumn(state, action.payload);
    case sitesActions.FETCH_ADMINS_SUCCESS:
      return { ...state, adminUsers: action.payload.results };
    case sitesActions.FETCH_ADMINS_FAILED:
      return { ...state, error: action.payload };
    case modalActions.OPEN_ADMINS_MODAL:
      return { ...state, adminsModal: { open: true } };
    case modalActions.CLOSE_ADMINS_MODAL:
      return { ...state, adminsModal: { open: false } };
    default:
      return state;
  }
};
