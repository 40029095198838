import { action } from 'typesafe-actions';

// opening the add users modal
export const OPEN_ADD_USERS_MODAL = 'OPEN_ADD_USERS_MODAL';
export const openAddUsersModal = () => action(OPEN_ADD_USERS_MODAL);

// closing the add users modal
export const CLOSE_ADD_USER_MODAL = 'CLOSE_ADD_USERS_MODAL';
export const closeAddUserModal = () => action(CLOSE_ADD_USER_MODAL);

// apply the current search input and include deleted checkbox to the currentSearchParams
// (dispatch ORG_USERS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const ORG_USERS_APPLY_SEARCH = 'ORG_USERS_APPLY_SEARCH';
export const applySearch = () => action(ORG_USERS_APPLY_SEARCH);

// search input changing
export const ORG_USERS_SEARCH_INPUT_CHANGE = 'ORG_USERS_SEARCH_INPUT_CHANGE';
export const searchInputChange = (value: string) => action(ORG_USERS_SEARCH_INPUT_CHANGE, { value });

export const ORG_USERS_SEARCH_PARAMS_CHANGE = 'ORG_USERS_SEARCH_PARAMS_CHANGE';
export const searchParamChange = (value: any) => action(ORG_USERS_SEARCH_PARAMS_CHANGE, { value });
