import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { Store } from 'redux';
import get from 'lodash/get';

import TabbedPanelInterface from 'common/components/TabbedPanelInterface/TabbedPanelInterface';
import TabbedPanel from 'common/components/TabbedPanelInterface/TabbedPanel';

import Users from '../Users';
import Sites from '../Sites';
import { fetchOrg } from 'organizationDashboard/actions/OrganizationActions';
import UsersRoutingTab from './UsersRoutingTab';
import SitesRoutingTab from './SitesRoutingTab';

import { OrgDashboardRoutesType } from './types';
import { ApplicationState } from 'organizationDashboard/types';

import './dashboard.scss';

interface Props {
  onFetchOrg: typeof fetchOrg;
  store: Store<ApplicationState | any>;
}

class Dashboard extends Component<Props> {
  componentDidMount() {
    const { onFetchOrg } = this.props;

    onFetchOrg();
  }

  render() {
    const { store } = this.props;

    const { basePath, usersPath, sitesPath }: OrgDashboardRoutesType = get(
      window,
      'socrata.orgDashboardRoutes',
      '/dashboard'
    );

    return (
      <Provider store={store}>
        <div className="dashboard-container">
          <TabbedPanelInterface store={store} basePath={basePath} defaultPath={usersPath}>
            <TabbedPanel component={Users} tabComponent={UsersRoutingTab} isVisible={true} path={usersPath} />
            <TabbedPanel component={Sites} tabComponent={SitesRoutingTab} isVisible={true} path={sitesPath} />
          </TabbedPanelInterface>
        </div>
      </Provider>
    );
  }
}

const mapDispatchToProps = {
  onFetchOrg: fetchOrg
};

export default connect(null, mapDispatchToProps)(Dashboard);
