import get from 'lodash/get';

import { getUsers, getRolesList } from 'organizationDashboard/components/selectors';
import { getOrg } from 'organizationDashboard/components/selectors';

import type { Role } from '@socrata/core-roles-api';
import type { ApplicationState, Status } from 'organizationDashboard/types';

import I18n from 'common/i18n';

interface DropDownOptions {
  title: string;
  value: string;
}

export const getSearchInputValue = (state: ApplicationState) => get(getUsers(state), 'searchInputValue', '');

export const getFormattedRoles = (roles: Role[]): DropDownOptions[] => {
  return roles.map(({ name, id }) => {
    const title: string = I18n.t(`org_dashboard.roles.${name}.name`);
    return {
      title,
      value: String(id)
    };
  });
};

export const getFilterableUserRoles = (state: ApplicationState): DropDownOptions[] => {
  const roles = getRolesList(state);
  return getFormattedRoles(roles);
};

export const getStatus = (state: ApplicationState): Status[] => get(getOrg(state), 'userStatus', []);

export const getFilterableStatus = (status: ApplicationState): DropDownOptions[] => {
  const allStatus = getStatus(status);
  return allStatus.map((value: string) => ({
    title: I18n.t(`org_dashboard.org_selectors.status.${value}`),
    value
  }));
};

export const getFormattedAddUserRoles = (state: ApplicationState) => {
  const roles = getRolesList(state).filter(({ name }: { name: string }) => name.includes('organization'));
  return getFormattedRoles(roles);
};
