import { action } from 'typesafe-actions';

export const OPEN_ADMINS_MODAL = 'OPEN_SITE_ADMINS_MODAL';
export const openAdminsModal = () => action(OPEN_ADMINS_MODAL);
type openAdminsModalAction = ReturnType<typeof openAdminsModal>;

export const CLOSE_ADMINS_MODAL = 'CLOSE_SITE_ADMINS_MODAL';
export const closeAdminsModal = () => action(CLOSE_ADMINS_MODAL);
type closeAdminsModalAction = ReturnType<typeof closeAdminsModal>;

export type adminsModalType = openAdminsModalAction | closeAdminsModalAction;
