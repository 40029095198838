import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import Button, { VARIANTS } from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import TextArea from 'common/components/Forms/TextArea';
import I18n from 'common/i18n';

import { closeAddUserModal } from '../ActionsBar/actions';
import { getUserModalState } from './selectors';
import { addUsers } from './actions';
import { getOrgAdminRole } from 'organizationDashboard/components/selectors';

import type { Role } from '@socrata/core-roles-api';
import { ApplicationState } from 'organizationDashboard/types';

import './add-users-modal.scss';

interface Props {
  showModal: ReturnType<typeof getUserModalState>;
  closeForm: typeof closeAddUserModal;
  addUsers: typeof addUsers;
  orgAdminRole: Role | undefined;
}

interface State {
  emails: string;
}

export class AddUsersModal extends Component<Props, State> {
  state = {
    emails: ''
  };

  clearState = () => {
    this.setState({
      emails: ''
    });
  };

  onSubmit = (e: { preventDefault: () => void }) => {
    const { emails } = this.state;
    const { orgAdminRole } = this.props;

    e.preventDefault();
    this.props.addUsers(emails, orgAdminRole!.id!);
    this.props.closeForm();
    this.clearState();
  };

  render() {
    const { showModal, closeForm, orgAdminRole } = this.props;
    const { emails } = this.state;

    if (showModal) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Modal fullScreen={false} onDismiss={closeForm}>
            <ModalHeader
              showCloseButton={false}
              onDismiss={() => {
                closeForm();
              }}
            >
              <h5>{I18n.t('org_dashboard.users.add_user_modal.title')}</h5>
              {I18n.t('org_dashboard.users.add_user_modal.title_details')}
            </ModalHeader>

            <ModalContent>
              <TextArea
                className="add-users__email-text-area"
                name="Emails"
                label={I18n.t('org_dashboard.users.add_user_modal.emails_label')}
                autoFocus
                onChange={(e: { target: { value: string } }) => this.setState({ emails: e.target.value })}
                value={emails}
              />
            </ModalContent>

            <ModalFooter>
              <div>
                <Button onClick={closeForm}>{I18n.t('org_dashboard.users.cancel')}</Button>{' '}
                <Button type="submit" variant={VARIANTS.PRIMARY} disabled={!orgAdminRole}>
                  {I18n.t('org_dashboard.users.submit')}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </Form>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  showModal: getUserModalState(state),
  orgAdminRole: getOrgAdminRole(state)
});

const mapDispatchToProps = {
  closeForm: closeAddUserModal,
  addUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersModal);
