import React from 'react';
import { connect } from 'react-redux';

import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';

import { openAddUsersModal } from './actions';

interface Props {
  handleClick: typeof openAddUsersModal;
}

export const AddAdministratorsButton = ({ handleClick }: Props) => (
  <Button onClick={handleClick} variant={VARIANTS.PRIMARY} className="add-administrators__open-modal-button">
    {I18n.t('org_dashboard.users.add_user_modal.title')}
  </Button>
);

const mapDispatchToProps = {
  handleClick: openAddUsersModal
};

export default connect(null, mapDispatchToProps)(AddAdministratorsButton);
