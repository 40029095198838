import React from 'react';

import I18n from 'common/i18n/index';
import Button from 'common/components/Button';

const downloadCsv = (e: { preventDefault: () => void }) => {
  e.preventDefault();
  window.location.replace(`${window.location}.csv`);
};

const DownloadCSV = () => (
  <Button className="btn btn-default download-csv" onClick={downloadCsv}>
    {I18n.t('org_dashboard.users.download_csv')}
  </Button>
);

export default DownloadCSV;
