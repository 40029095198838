import { Status, Users } from 'organizationDashboard/types';
import { defaultSearchParams } from 'organizationDashboard/constants';

export const status: { ALL: Status; DEACTIVATED: Status; ACTIVE: Status; PENDING: Status } = {
  ALL: 'all',
  PENDING: 'pending',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const defaultUserState: Users = {
  currentSearchParams: defaultSearchParams,
  currentPage: [],
  previousSeeks: [],
  hasNextPage: false,
  searchInputValue: '',
  error: null,
  addUsersModalOpen: false,
  roles: []
};
