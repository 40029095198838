import { action } from 'typesafe-actions';
import { SearchParameters, User } from 'organizationDashboard/types';

export const SET_ADMIN_SEARCH_PARAMS = 'SET_ADMIN_SEARCH_PARAMS';
export const setAdminSearchParams = (adminSearchParams: SearchParameters) =>
  action(SET_ADMIN_SEARCH_PARAMS, { adminSearchParams });
type setAdminSearchParamsAction = ReturnType<typeof setAdminSearchParams>;

export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';
export const fetchAdminUsers = () => action(FETCH_ADMIN_USERS);
type fetchAdminUsersAction = ReturnType<typeof fetchAdminUsers>;

export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const fetchAdminsSuccessful = (results: User[]) => action(FETCH_ADMINS_SUCCESS, { results });
type fetchAdminsSuccessfulAction = ReturnType<typeof fetchAdminsSuccessful>;

export const FETCH_ADMINS_FAILED = 'FETCH_ADMINS_FAILED';
export const fetchAdminsFailed = (error: any) => action(FETCH_ADMINS_FAILED, { error });
type fetchAdminsFailedAction = ReturnType<typeof fetchAdminsFailed>;

export const SITE_ADMINS_CHANGE_SORT_COLUMN = 'SITE_ADMINS_CHANGE_SORT_COLUMN';
export const changeSiteAdminsSortColumn = (columnName: string) =>
  action(SITE_ADMINS_CHANGE_SORT_COLUMN, { columnName });
type changeSiteAdminsSortColumnAction = ReturnType<typeof changeSiteAdminsSortColumn>;

export type sitesActionType =
  | setAdminSearchParamsAction
  | fetchAdminsFailedAction
  | fetchAdminUsersAction
  | fetchAdminsSuccessfulAction
  | changeSiteAdminsSortColumnAction;
