import get from 'lodash/get';
import React, { Component } from 'react';

import { DropdownItem } from 'common/components/DropdownButton';

import * as Actions from './actions';
import { translateTableAction } from 'organizationDashboard/components/Users/translate';
import type { Role } from '@socrata/core-roles-api';
import type { User } from 'organizationDashboard/types';

export interface Props {
  /** User's email */
  email: string;
  user: User;
  orgAdminRole: Role | undefined;
  orgMemberRole: Role | undefined;
  setUserRole: typeof Actions.setUserRole;
}

/** A button that toggles a user's role between Org Admin and Org Member. Only visible if this action makes sense. */
export default class AdminRoleToggleButton extends Component<Props> {
  render = () => {
    const { orgAdminRole, orgMemberRole, user, setUserRole, email } = this.props;
    const userUid = get(user, 'uid');
    const userRoleId = get(user, 'organizationRoleId');
    const orgAdminRoleId = get(orgAdminRole, 'id');
    const orgMemberRoleId = get(orgMemberRole, 'id');

    const isAdmin = userRoleId !== undefined && orgAdminRoleId === userRoleId;

    const roleIdToChangeTo = isAdmin ? orgMemberRoleId : orgAdminRoleId;

    const confirmTranslationKey = isAdmin
      ? 'status.remove_org_admin.confirm'
      : 'status.add_org_admin.confirm';
    const actionTranslationKey = isAdmin
      ? 'status.remove_org_admin.button_label'
      : 'status.add_org_admin.button_label';

    if (
      !user ||
      userUid === undefined || // Pending user? Anyway we can't do anything with this.
      roleIdToChangeTo === undefined // Something is wrong with the roles.
    ) {
      return null;
    }

    return (
      <DropdownItem
        onClick={() => {
          if (window.confirm(translateTableAction(confirmTranslationKey, { email }))) {
            setUserRole(userUid, roleIdToChangeTo);
          }
        }}
      >
        {translateTableAction(actionTranslationKey)}
      </DropdownItem>
    );
  };
}
