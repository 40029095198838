import React from 'react';

import OrganizationUsersTable from './OrganizationUsersTable';
import ActionsBar from './ActionsBar';

export const Users = () => (
  <div>
    <ActionsBar />
    <OrganizationUsersTable />
  </div>
);

export default Users;
