import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';

import { applySearch, searchInputChange } from './actions';
import { getSearchInputValue } from './selectors';

import { ApplicationState } from 'organizationDashboard/types';
import { IconName } from 'common/components/SocrataIcon';

interface Props {
  searchInputValue: ReturnType<typeof getSearchInputValue>;
  doApplySearch: typeof applySearch;
  onSearchInputChange: typeof searchInputChange;
}

class SearchBar extends Component<Props> {
  onSearchSubmit = (e: { preventDefault: () => void }) => {
    const { doApplySearch } = this.props;

    e.preventDefault();
    doApplySearch();
  };

  changeSearchInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const { onSearchInputChange } = this.props;
    onSearchInputChange(value);
  };

  render() {
    const { searchInputValue } = this.props;

    return (
      <div className="org-users__top-bar">
        <Form onSubmit={this.onSearchSubmit} className="org-users__search-form">
          <Input
            className="org-users__search-form__input"
            onChange={this.changeSearchInput}
            value={searchInputValue}
            placeholder={I18n.t('org_dashboard.users.search_placeholder')}
            label={I18n.t('org_dashboard.users.search')}
            name="search"
            iconName={IconName.Search}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  searchInputValue: getSearchInputValue(state)
});

const mapDispatchToProps = {
  doApplySearch: applySearch,
  onSearchInputChange: searchInputChange
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
