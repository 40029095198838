import React, { Component } from 'react';

import { DropdownItem } from 'common/components/DropdownButton';

import { ORGANIZATION_USER_STATUS } from './constants';
import * as Actions from './actions';
import { translateTableAction } from 'organizationDashboard/components/Users/translate';

import { User } from 'organizationDashboard/types';

export interface Props {
  /** User's email */
  email: string;
  user: User;
  setUserStatusActive: typeof Actions.setUserStatusActive;
  setUserStatusDeactivated: typeof Actions.setUserStatusDeactivated;
}

/** A button that disables or enables a user account */
export default class UserDisableToggleButton extends Component<Props> {
  onDeactivateUser = () => {
    const { user, setUserStatusDeactivated, email } = this.props;

    if (window.confirm(translateTableAction('status.deactivate.confirm', { email }))) {
      setUserStatusDeactivated(user);
    }
  };

  onActivateUser = () => {
    const { user, setUserStatusActive, email } = this.props;

    if (window.confirm(translateTableAction('status.activate.confirm', { email }))) {
      setUserStatusActive(user);
    }
  };

  render = () => {
    switch (this.props.user.status) {
      case ORGANIZATION_USER_STATUS.ACTIVE:
        return (
          <DropdownItem onClick={() => this.onDeactivateUser()}>
            {translateTableAction('status.deactivate.button_label')}
          </DropdownItem>
        );
      case ORGANIZATION_USER_STATUS.PENDING:
        return (
          <DropdownItem disabled={true} onClick={() => ''}>
            {translateTableAction('status.deactivate.button_label')}
          </DropdownItem>
        );
      case ORGANIZATION_USER_STATUS.DEACTIVATED:
        return (
          <DropdownItem onClick={() => this.onActivateUser()}>
            {translateTableAction('status.activate.button_label')}
          </DropdownItem>
        );
    }

    return null;
  };
}
