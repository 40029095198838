import { call, put, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash/keyBy';

import { fetchJsonWithParsedError } from 'common/http';

import * as actions from 'organizationDashboard/actions/OrganizationActions';

import { Organization } from 'organizationDashboard/types';

export function* fetchOrganization() {
  try {
    const org: Organization = yield call(fetchJsonWithParsedError, '/api/organizations/current.json');
    //memoize websites and build a lookup for user role ids.
    if (org && org.domains) {
      org.sites = {};
      org.domains.map(({ cname, name }): string => (org.sites[cname] = name));
      org.userRolesDictionary = keyBy(org.userRoles, 'id');
    }

    yield put(actions.fetchOrgSuccess(org));
    yield put(actions.initialLoad());
  } catch (error) {
    console.error(`Error fetching sites; requestId ${error.requestId}`, error.json);
    yield put(actions.fetchOrgFailed(error));
  }
}

export default [takeEvery(actions.FETCH_ORG, fetchOrganization)];
