import get from 'lodash/get';
import find from 'lodash/find';
import { Dictionary } from 'lodash';

import { defaultUserState } from 'organizationDashboard/components/Users/constants';
import { filterSitesWithoutDashboard } from 'organizationDashboard/util';

import type { Role } from '@socrata/core-roles-api';
import type { Domain, Organization, ApplicationState, Users } from 'organizationDashboard/types';

export const getOrg = (state: ApplicationState): Organization | any => get(state, 'organization', {});

export const getUsers = (state: ApplicationState): Users => get(state, 'users', defaultUserState);

export const getSites = (state: ApplicationState): Domain[] => get(getOrg(state), 'domains', []);

export const getRolesList = (state: ApplicationState): Role[] => get(getOrg(state), 'userRoles', []);

export const getRolesMap = (state: ApplicationState): Dictionary<Role> =>
  get(getOrg(state), 'userRolesDictionary');

// Filter the org dashboard out of the given list of sites //
export const getSitesWithoutDashboard = (state: ApplicationState): Domain[] => {
  const sites = getSites(state);
  return filterSitesWithoutDashboard(sites);
};

type filteredDropdownValues = { title: string; value: string }[];

export const getFilterableSites = (state: ApplicationState): filteredDropdownValues => {
  const sites = getSitesWithoutDashboard(state);
  return sites.map(({ name, cname }) => ({
    title: name,
    value: cname
  }));
};

/**
 * @return total number of users on the org
 */
export const getNumberOfUsers = (state: ApplicationState): number =>
  get(getUsers(state), 'totalNumberOfUsers', -1);

// We exclude the org domain which we filter out and do not show anyways
export const getNumberOfDomains = (state: ApplicationState): number =>
  get(getOrg(state), 'numberOfDomains', 0) - 1;

// Gets the domain's org admin role.
export const getOrgAdminRole = (state: ApplicationState): Role | undefined =>
  find(getRolesList(state), { name: 'organization_administrator' });

// Gets the domain's org member role.
export const getOrgMemberRole = (state: ApplicationState): Role | undefined =>
  find(getRolesList(state), { name: 'organization_member', isDefault: true });
