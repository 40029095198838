import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import I18n from 'common/i18n';
import Spinner from 'common/components/Spinner';
import ResultsTable from 'common/components/ResultsTable';
import { currentUserIsSuperAdmin } from 'common/current_user';

import SuperadminDropdownButton from './SuperadminDropdownButton';
import { getErrors } from './selectors';
import { getSitesWithoutDashboard } from '../selectors';
import AdminsModal from './AdminsModal';
import AdminsModalButton from './AdminsModal/AdminsModalButton';

import { ApplicationState, Domain } from 'organizationDashboard/types';

interface Props {
  sites: Domain[];
  error: any;
}

export class Sites extends Component<Props> {
  /** Locale datetime format for presentation */
  static formatDate = (date: string): string => moment(date).format('LLLL');
  static headerForColumn = (column: string) => I18n.t(`org_dashboard.sites.table.${column}.header`);

  render() {
    const { sites, error } = this.props;

    if (!sites && !error) {
      return <Spinner />;
    }

    if (error) {
      return (
        <div className="alert error">
          <p>{I18n.t('org_dashboard.sites.table.error.title')}</p>
          {/* Eventually we'll want to parse this and show something better */}
          <p className="sites-error-message">{error.json && error.json.message}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: I18n.t('org_dashboard.sites.table.error.contact_support_html', {
                requestId: error.requestId
              })
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <ResultsTable
          data={sites}
          rowKey="cname"
          noResultsMessage={I18n.t('org_dashboard.sites.table.no_results')}
        >
          {/** Site name */}
          <ResultsTable.Column header={Sites.headerForColumn('name')} dataIndex="name">
            {(name: string, { cname }: Domain) => (
              <a key={cname} href={`https://${cname}`} target="_blank">
                {name}
              </a>
            )}
          </ResultsTable.Column>
          {/** datetime when the domain was created */}
          <ResultsTable.Column header={Sites.headerForColumn('created_at')} dataIndex="createdAt">
            {(createdAt: string, site: Domain) => (
              <span data-date-label-for={site.cname}>{Sites.formatDate(createdAt)}</span>
            )}
          </ResultsTable.Column>
          {/** site admins count within a button */}
          <ResultsTable.Column header={Sites.headerForColumn('number_of_site_admins')} dataIndex="stats">
            {(stats: { roles: { administrator: number } }, site: Domain) => (
              <span data-number-of-site-admins-label-for={site.cname}>
                <AdminsModalButton site={site} numberOfSiteAdmins={stats.roles.administrator} />
              </span>
            )}
          </ResultsTable.Column>
          {/** Additional actions for superadmin users */}
          {currentUserIsSuperAdmin() && (
            <ResultsTable.Column header={Sites.headerForColumn('superadmin_actions')} dataIndex="cname">
              {(cname: string, site: Domain) => <SuperadminDropdownButton site={site} />}
            </ResultsTable.Column>
          )}
        </ResultsTable>
        <AdminsModal />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  sites: getSitesWithoutDashboard(state),
  error: getErrors(state)
});

// "pure: false" here is added because this is a child of a `TabbedPanelInterface` which causes
// some weird issues with redux...
export default connect(mapStateToProps, null, null, { pure: false })(Sites as any);
