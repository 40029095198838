import React from 'react';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';

import { createDropdownOptionsWithDefault } from '../util';
import { UserDomain } from 'organizationDashboard/types';

const SitesDropdown = ({
  sites,
  cNameToNameTranslations
}: {
  sites: UserDomain[];
  cNameToNameTranslations: any;
}) => {
  //No sites to display
  if (!sites) return null;
  //One or more sites to display
  const placeholder =
    sites.length > 1
      ? `${sites.length} ${I18n.t('org_dashboard.users.table.sites.header')}`
      : I18n.t('org_dashboard.users.table.sites.default_value');
  const displayableSites = sites.map(({ domainCname }) => ({
    title: cNameToNameTranslations[domainCname],
    value: domainCname
  }));
  return (
    <Dropdown
      placeholder={placeholder}
      options={createDropdownOptionsWithDefault(placeholder, displayableSites)}
      picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
    />
  );
};

export default SitesDropdown;
