import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import I18n from 'common/i18n';
import RoutingTab from 'common/components/TabbedPanelInterface/RoutingTab';

import { getNumberOfDomains } from 'organizationDashboard/components/selectors';
import { areAssetsLoaded } from 'organizationDashboard/util';

import { OrgDashboardRoutesType } from './types';
import { ApplicationState } from 'organizationDashboard/types';

interface Props {
  numberOfDomains: ReturnType<typeof getNumberOfDomains>;
}

export const SitesRoutingTab = ({ numberOfDomains }: Props) => {
  const { sitesPath }: OrgDashboardRoutesType = get(window, 'socrata.orgDashboardRoutes', '/dashboard');

  return (
    <RoutingTab
      loading={areAssetsLoaded(numberOfDomains)}
      key={sitesPath}
      count={numberOfDomains}
      to={sitesPath}
    >
      {I18n.t('org_dashboard.sites.title')}
    </RoutingTab>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  numberOfDomains: getNumberOfDomains(state)
});

export default connect(mapStateToProps, null, null, { pure: false })(SitesRoutingTab);
