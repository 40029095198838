import { Organization } from 'organizationDashboard/types';
import { action } from 'typesafe-actions';

export const FETCH_ORG = 'FETCH_ORG';
export const fetchOrg = () => action(FETCH_ORG);
type fetchOrgAction = ReturnType<typeof fetchOrg>;

export const FETCH_ORG_SUCCESS = 'FETCH_ORG_SUCCESS';
export const fetchOrgSuccess = (organization: Organization) => action(FETCH_ORG_SUCCESS, { organization });
type fetchOrgSuccessAction = ReturnType<typeof fetchOrgSuccess>;

export const FETCH_ORG_FAILED = 'FETCH_ORG_FAILED';
export const fetchOrgFailed = (error: any) => action(FETCH_ORG_FAILED, { error });
type fetchOrgFailedAction = ReturnType<typeof fetchOrgFailed>;

export const INITIAL_LOAD = 'INITIAL_LOAD';
export const initialLoad = () => action(INITIAL_LOAD);
type initialLoadAction = ReturnType<typeof initialLoad>;

export type organizationActionType =
  | fetchOrgAction
  | fetchOrgSuccessAction
  | fetchOrgFailedAction
  | initialLoadAction;
