import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import I18n from 'common/i18n';
import RoutingTab from 'common/components/TabbedPanelInterface/RoutingTab';

import { getNumberOfUsers } from 'organizationDashboard/components/selectors';
import { areAssetsLoaded } from 'organizationDashboard/util';

import { OrgDashboardRoutesType } from './types';
import { ApplicationState } from 'organizationDashboard/types';

interface Props {
  numberOfUsers: number;
}

class UsersRoutingTab extends Component<Props> {
  render() {
    const { usersPath }: OrgDashboardRoutesType = get(window, 'socrata.orgDashboardRoutes', '/dashboard');
    const { numberOfUsers } = this.props;

    return (
      <RoutingTab
        loading={areAssetsLoaded(numberOfUsers)}
        key={usersPath}
        count={numberOfUsers}
        to={usersPath}
      >
        {I18n.t('org_dashboard.users.title')}
      </RoutingTab>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  numberOfUsers: getNumberOfUsers(state)
});

export default connect(mapStateToProps, null, null, { pure: false })(UsersRoutingTab);
