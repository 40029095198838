import { SORT_DIRECTION } from 'common/Constants';
import {
  PAGE_SIZE,
  COLUMN_NAMES
} from 'organizationDashboard/components/Users/OrganizationUsersTable/constants';

import { SearchParameters } from './types';

/** arbitrary limit for the max number of site admins the API can fetch */
export const MAX_ADMINS_TO_FETCH = 1000;

export const defaultSearchParams: SearchParameters = {
  /** we fetch an extra one to see if there's another page */
  limit: PAGE_SIZE + 1,
  status: 'all',
  sortBy: COLUMN_NAMES.EMAIL,
  sortOrder: SORT_DIRECTION.ASC,
  seekEmail: '',
  q: null,
  domainCname: null,
  roleId: null
};
