import get from 'lodash/get';

import { ApplicationState } from 'organizationDashboard/types';
import { defaultSearchParams } from 'organizationDashboard/constants';

export const getSites = (state: ApplicationState) => get(state, 'sites', {});
export const getErrors = (state: ApplicationState) => get(getSites(state), 'error', null);
export const getFetchedSiteAdmins = (state: ApplicationState) => get(getSites(state), 'adminUsers', []);
export const getAdminsModalState = (state: ApplicationState) =>
  get(getSites(state), 'adminsModal.open', false);
export const getSiteAdminsSearchParams = (state: ApplicationState) =>
  get(getSites(state), 'adminSearchParams', defaultSearchParams);
