import React from 'react';
import { connect } from 'react-redux';

import DropdownButton from 'common/components/DropdownButton';

import * as Actions from './actions';
import { getOrgAdminRole, getOrgMemberRole } from 'organizationDashboard/components/selectors';
import AdminRoleToggleButton from './AdminRoleToggleButton';
import UserDisableToggleButton from './UserDisableToggleButton';

import type { Role } from '@socrata/core-roles-api';
import type { User, ApplicationState } from 'organizationDashboard/types';

interface Props {
  email: string;
  user: User;
  orgAdminRole: Role | undefined;
  orgMemberRole: Role | undefined;
  setUserStatusActive: typeof Actions.setUserStatusActive;
  setUserStatusDeactivated: typeof Actions.setUserStatusDeactivated;
  setUserRole: typeof Actions.setUserRole;
}

/** Button placed at the end of each row of user results */
export const ActionsDropdownButton = (props: Props) => (
  <DropdownButton>
    <UserDisableToggleButton {...props} />
    <AdminRoleToggleButton {...props} />
  </DropdownButton>
);

const mapStateToProps = (state: ApplicationState) => ({
  orgAdminRole: getOrgAdminRole(state),
  orgMemberRole: getOrgMemberRole(state)
});

const mapDispatchToProps = {
  setUserStatusActive: Actions.setUserStatusActive,
  setUserStatusDeactivated: Actions.setUserStatusDeactivated,
  setUserRole: Actions.setUserRole
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsDropdownButton);
